@tailwind base;
@tailwind components;
@tailwind utilities;

#__next {
  min-height: 100%;
}

.ohnohoney {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -50;
}
